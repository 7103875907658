'use strict';

class PwResetController {
  constructor(Auth, $state, $http) {
    this.user = {};
    this.loading = false;
    this.Auth = Auth;
    this.$state = $state;
    this.$http = $http;
  }

  reset(form) {
    this.loading = true;
    this.$http.post('/api/pw-resets/generate/' + this.user.email).then(res => {
      alert('Ti abbiamo inviato per email le istruzioni per reimpostare la tua password');
      this.loading = false;
      this.$state.go('login');
    }).catch(err => {
      console.log(err);
      alert('Errore: controlla l\'indirizzo email inserito e riprova');
      this.loading = false;
    });
  }
}

angular.module('appApp')
  .controller('PwResetController', PwResetController);
